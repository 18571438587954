<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        Layouts
        <v-spacer />
        <v-btn
          color="success"
          :to="{ name: 'layouts-new' }"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          New Layout
        </v-btn>
      </v-card-title>
      <v-card-text>
        Every display can use one layout at a time. Layouts define the structure, functionality and look of your display. Click on a layout below to see the details and configuration options. Changing a layout will update all the displays it is linked with.
      </v-card-text>

      <!-- templates -->

      <!-- layouts -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="processedItems"
        item-key="name"
        class="m-data-table"
        no-data-text="No layouts to show"
      >
        <!-- show-select -->
        <!-- show-expand -->
        <template #[`item.name`]="{ item }">
          <v-list-item
            :to="{ name: 'layout', params: { id: item.id } }"
            dense
            selectable
            class="ma-0 pa-0"
            style="max-width: 250px;"
          >
            <v-list-item-icon class="mr-1">
              <v-icon>
                mdi-collage
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.minPlan }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #[`item.min_plan`]="{ item }">
          <span>
            {{ item.minPlan | displayPlan }}
          </span>
        </template>
        <template #[`item.displays`]="{ item }">
          <span>
            {{ item.displays.length }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import { storageRef, db } from '@/plugins/firebase'
export default {
  name: 'Layouts',
  data: () => ({
    useLayout: false,
    selected: [],
    headers: [
      {
        text: 'Layout Name',
        align: 'left',
        value: 'name'
      },
      {
        text: 'Minimum Plan',
        value: 'min_plan'
      },
      {
        text: 'Active Displays',
        align: 'right',
        value: 'displays'
      }
    ]
  }),
  computed: {
    ...mapGetters(['layouts']),
    processedItems() {
      return this.layouts.map((x) => {
        return x
      })
    }
  }
}
</script>
